<template>
  <SpecialOfferBar v-if="specialOfferBar" :bar-data="specialOfferBar" />
  <InfoBar v-else-if="infoBar" :bar-data="infoBar" />
</template>

<script>
import SpecialOfferBar from './SpecialOfferBar.vue';
import InfoBar from './InfoBar.vue';

export default {
  name: 'TopBar',
  components: {
    SpecialOfferBar,
    InfoBar
  },
  async mounted () {
    if (!this.isBaners) {
      await this.$store.dispatch('cmsBlock/list', { filterValues: null })
    }
  },
  computed: {
    validBaners () {
      return this.$store.state.cmsBlock.baners;
    },
    isBaners () {
      return Object.keys(this.validBaners).length;
    },
    specialOfferBar () {
      return this.validBaners.specialOfferBar;
    },
    infoBar () {
      return this.validBaners.infoBarHeader;
    }
  }
}
</script>
