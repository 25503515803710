<template>
  <div
    class="special-offer-container bg-cl-special-offer h-100 flex middle-xs center-xs cl-white weight-600 py5"
  >
    <div class="title">
      {{ barData.text }}
    </div>
    <div class="flex">
      <div v-for="(el, key) in separatedEndDate" :key="key" class="flex">
        <div>
          <div class="date-box bg-cl-main cl-white fs12 letter-spacing-3 brdr-5px">
            <template v-if="intervalId">
              {{ el | twoDigits }}
            </template>
          </div>
          <div class="fs10 date-box--title">
            {{ $t(key) }}
          </div>
        </div>
        <span v-if="key !== 'seconds'" class="separator px5">:</span>
      </div>
    </div>
    <div class="title hidden-xs">
      {{ barData.text }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'SpecialOfferBar',
  filters: {
    twoDigits (value) {
      return value.toString().padStart(2, '0');
    }
  },
  props: {
    barData: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      timeRemaining: 0,
      intervalId: null
    };
  },
  mounted () {
    this.calculateTimeRemaining();
    this.intervalId = setInterval(this.calculateTimeRemaining, 1000);
  },
  beforeDestroy () {
    clearInterval(this.intervalId);
  },
  computed: {
    targetDate () {
      const [datePart, timePart] = this.barData.endDate.split(', ');
      const [day, month, year] = datePart.split('.').map(Number);
      const [hours, minutes] = timePart.split(':').map(Number);

      return new Date(year, month - 1, day, hours, minutes);
    },
    separatedEndDate () {
      return {
        days: Math.floor(this.timeRemaining / (3600 * 24)),
        hours: Math.floor((this.timeRemaining % (3600 * 24)) / 3600),
        minutes: Math.floor((this.timeRemaining % 3600) / 60),
        seconds: this.timeRemaining % 60
      };
    }
  },
  methods: {
    calculateTimeRemaining () {
      const target = new Date(this.targetDate).getTime();
      const now = Date.now();
      this.timeRemaining = Math.max(0, Math.floor((target - now) / 1000));

      if (this.timeRemaining === 0) {
        clearInterval(this.intervalId);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.special-offer-container {
  gap: 30px;
  @media (max-width: 767px){
    flex-direction: column;
    gap: 0;
  }
  .title {
    font-size: 22px;
    font-weight: 500;
    @media (max-width: 767px){
      font-size: 18px;
    }
  }
}
.date-box {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 767px) {
    width: 30px;
    height: 30px;
    font-size: 11px;
    font-weight: 500;
  }
  &--title {
    @media (max-width: 767px) {
      font-weight: 500;
    }
  }
}
.separator {
  margin-top: 8px;
}
</style>
